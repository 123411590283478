export const projectBootstrapTemplates = [
  {
    display_name: 'Restoran',
    name: 'restoran',
    thumbnail: 'modes/preview_templates/restoran.png',
    available_in: ['PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'LifeSure',
    name: 'lifesure',
    thumbnail: 'modes/preview_templates/lifesure.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Fruitables',
    name: 'fruitables',
    thumbnail: 'modes/preview_templates/fruitables.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Furni',
    name: 'furni',
    thumbnail: 'modes/preview_templates/furni.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  },
  {
    display_name: 'Modernize',
    name: 'modernize',
    thumbnail: 'modes/preview_templates/MZRD.png',
    available_in: ['STARTER_MONTHLY', 'STARTER_ANNUAL', 'PROFESSIONAL_MONTHLY', 'PROFESSIONAL_ANNUAL', 'ELITE_MONTHLY', 'ELITE_ANNUAL']
  }
];

export const projectBootstrapTemplatesAsLowerCaseStrings = projectBootstrapTemplates.map(template => template.name.toLowerCase());