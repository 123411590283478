import { atom } from 'recoil';

export const loadingState = atom({
  key: 'loading',
  default: false,
});

export const IsGeneratingSpinner = atom({
  key: 'is-generating-spinner',
  default: false,
});

export const IsGeneratingLoadingScreen = atom({
  key: 'is-generating-loading-screen',
  default: false,
});

export const IsGeneratingImageSpinner = atom({
  key: 'is-generating-image-spinner',
  default: false,
});

export const IsTranscribingSpinner = atom({
  key: 'is-transcribing-spinner',
  default: false,
});

export const IsDeployingSpinner = atom({
  key: 'is-deploying-spinner',
  default: false,
});

export const IsUploadingSpinner = atom({
  key: 'is-uploading-spinner',
  default: false,
});