import { atom, selector, DefaultValue } from 'recoil';
import { Page } from '../types';

export const pagesState = atom<Page[]>({
    key: 'pages',
    default: [],
});

export const PagesPaths = selector<any>({
    key: 'pages-paths',
    get: ({ get }) => {
        const fullPages: any = get(pagesState);
        if (fullPages) {
            const pathsOnly = fullPages.map((fp: any) => {
                return fp.path;
            })

            // console.log(pathsOnly);
            return pathsOnly;
        }
        return [];
    }
});

const selectedPageState = atom<string | null>({
    key: 'select-page',
    default: null,
});

export const selectedPageSelector = selector<Page | null>({
    key: 'selected-page-selector',
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) return;

        if (newValue)
            set(selectedPageState, newValue.id);
        else
            set(selectedPageState, null);
    },
    get: ({ get }) => {
        const pages = get(pagesState);
        const selectedId = get(selectedPageState);

        if (!selectedId) return null;
        return pages.find((page: Page) => page.id === selectedId) || null;
    },
});

export const PageLinks = atom({
    key: 'page-links',
    default: []
});

export const PageImages = atom({
    key: 'page-images',
    default: []
});

export const PageForms = atom({
    key: 'page-forms',
    default: []
});

export const PageLinksHaveBeenUpdated = atom({
    key: 'page-links-have-been-updated',
    default: false
});

export const PageImagesHaveBeenUpdated = atom({
    key: 'page-images-have-been-updated',
    default: false
});

export const PageFormsHaveBeenUpdated = atom({
    key: 'page-forms-have-been-updated',
    default: false
});