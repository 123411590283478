import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { currentUserState, projectsState, selectedProjectSelector, UserPreferences } from '../state';
import { User } from '../types';
import { useApiActions } from './api-actions';
import { useToast } from '@chakra-ui/react';

export const useUserActions = () => {
  const toast = useToast();
  const setUser = useSetRecoilState(currentUserState);
  const setProjects = useSetRecoilState(projectsState);
  const setSelectedProject = useSetRecoilState(selectedProjectSelector);
  const setUserPreferences = useSetRecoilState(UserPreferences);

  const navigate = useNavigate();
  const location = useLocation();
  const { executeApiAction } = useApiActions();

  const getMe = () =>
    executeApiAction<User>({
      action: async () => {
        const { me } = await ApiClient.getMe();
        return me;
      },
    });

  const register = async (firstName: string, lastName: string, email: string, password: string) =>
    executeApiAction<{ me: User }>({
      action: async () => ApiClient.register(firstName, lastName, email, password),
      onSuccess: async ({ access_token, session_id }: { access_token: string, session_id: string }) => {

        // Once user is created, create its preferences object in the database,
        // then, I'll be able to update it consecutively,
        // and I'll load it on login, authnPassword. Instead of useEffect.
        // meaning, I'll need a initialiseUserPreferences function.

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('session_id', session_id);
        const continueUrl = new URLSearchParams(location.search).get('continue') || '/';
        navigate(continueUrl);
        const user = await getMe();
        if (user) {
          setUser(user);
  
          const initialPreferences = {
            display_tips_modal_on_projects_list: true,
            display_tooltips: true,
            ai_model: 'model-1',
            image_model: 'image-1',
            ai_actions: false,
            create_pages_automatically_on_linking: false,
          };
  
          await initUserPreferences(user.id, initialPreferences);
          // Fetch the preferences after initialization
          const userPreferences = await fetchUserPreferences(user.id);
          if (userPreferences) {
            setUserPreferences(userPreferences);
          }
        }
      },
      errorMessage: 'Failed to register user',
    });

  const authnPassword = (email: string, password: string, remember: boolean) =>
    executeApiAction({
      action: () => ApiClient.authnPassword(email, password),
      onSuccess: async (response: { access_token: string, session_id: string }) => {
        const { access_token, session_id } = response;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('session_id', session_id);
        localStorage.setItem('remember_toggled', remember ? 'true' : 'false');
        if (remember) {
          localStorage.setItem('remembered_user_identifier', email);
        } else {
          localStorage.removeItem('remembered_user_identifier');
        }

        const userState = await Promise.all([ApiClient.getMe(), ApiClient.getProjects()]);
        setProjects(userState[1]);
        setUser(userState[0].me);

        // fetchUserPreferences(userState[0].me.id);
        const userPreferences = await fetchUserPreferences(userState[0].me.id);
      if (userPreferences) {
        setUserPreferences(userPreferences);
      }
      },
      errorMessage: 'Wrong user name or password',
    });

  const socialAuth = (email: string, firstName: string, lastName: string, remember: boolean) =>
    executeApiAction({
      action: () => ApiClient.authnSocial(email, firstName, lastName),
      onSuccess: async (response: { access_token: string, session_id: string, is_new_user: boolean }) => {
        const { access_token, session_id, is_new_user: isNewUser } = response;
        console.log("Full response received:", response);

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('session_id', session_id);
        localStorage.setItem('remember_toggled', remember ? 'true' : 'false');
        if (remember) {
          localStorage.setItem('remembered_user_identifier', email);
        } else {
          localStorage.removeItem('remembered_user_identifier');
        }

        const userState = await Promise.all([ApiClient.getMe(), ApiClient.getProjects()]);
        console.log("USERSTATE ", userState)
        setProjects(userState[1]);
        setUser(userState[0].me);
        if (isNewUser) {
          console.log("Initializing preferences for new user.");
          const initialPreferences = {
            display_tips_modal_on_projects_list: true,
            display_tooltips: true,
            ai_model: 'model-1',
            image_model: 'image-1',
            ai_actions: false,
            create_pages_automatically_on_linking: false,
          };
          await initUserPreferences(userState[0].me.id, initialPreferences);
          const userPreferences = await fetchUserPreferences(userState[0].me.id);
      if (userPreferences) {
        setUserPreferences(userPreferences);
      }
        }
         else {
          const userPreferences = await fetchUserPreferences(userState[0].me.id);
      if (userPreferences) {
        setUserPreferences(userPreferences);
      }
          console.log("User is not new, skipping preference initialization.");
        }
      },
      errorMessage: 'User email already exists',
    });

  const resetPassword = (email: string) =>
    executeApiAction({
      action: () => ApiClient.resetPassword(email),
      successMessage:
        'Your request was successful. If there is an account connected to this email, you will soon get an email with instructions on how to reset your password.',
      errorMessage: 'Something went wrong when processing your request. Please try again later.',
    });

  const verifyResetPassword = (password: string, resetToken: string) =>
    executeApiAction({
      action: () => ApiClient.verifyResetPassword(password, resetToken),
      successMessage: 'Your password was successfully set.',
      errorMessage: 'Your reset link is invalid. Request a new one on the reset password page.',
      resetSuccess: true,
    });
  const verifyUser = (verifyToken: string) =>
    executeApiAction({
      action: () => ApiClient.verifyUser(verifyToken),
      onSuccess: async () => {
        const user = await getMe();
        if (user) setUser(user);
        navigate('/');
      },
      successMessage: 'Your user was successfully verified',
      errorMessage: 'Failed to verify user',
      errorMessageDuration: null,
    });

  const resendVerification = () =>
    executeApiAction({
      action: () => ApiClient.resendVerification(),
      onSuccess: () => {
        toast({
          title: 'Verification email re-sent.',
          description: 'Check your email for instructions.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      },
      successMessage: 'Verification re-sent',
      errorMessage: 'Failed to re-send verification',
    });

  const signOut = () =>
    executeApiAction({
      action: () => ApiClient.signOut(),
      onSuccess: () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('selected_project_id');
        localStorage.removeItem('selected_page_id');

        setUser(null);
            setProjects([]);
            setSelectedProject(null);
            setUserPreferences(null);
        navigate('/sign-in');
      },
    });

  const getRememberInfo = (): { identifier: string | undefined; remember: boolean | undefined } => {
    const identifier = localStorage.getItem('remembered_user_identifier') || undefined;
    const rememberToggled = localStorage.getItem('remember_toggled');

    const remember = rememberToggled ? rememberToggled === 'true' : undefined;
    return { remember, identifier };
  };

  const initUserPreferences = async (userId: string, userPreferences: any) =>
    await executeApiAction({
      action: () => ApiClient.initUserPreferences(userId, userPreferences),
      successMessage: 'User preferences initialized.',
      errorMessage: 'Failed to initialize user preferences.',
    });

  const updateUserPreferences = (userId: string, userPreferences: any) =>
    executeApiAction({
      action: () => ApiClient.updateUserPreferences(userId, userPreferences),
      successMessage: 'User preferences saved.',
      errorMessage: 'User preferences failed to be saved.',
    });


  const fetchUserPreferences = async (userId: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchUserPreferences(userId),
      onSuccess: (user_preferences: any) => {        
        if (user_preferences) {
          setUserPreferences(user_preferences);
        } else {
          setUserPreferences({
            display_tips_modal_on_projects_list: true,
            display_tooltips: true,
            ai_model: 'model-1',
            image_model: 'image-1',
            ai_actions: false,
            create_pages_automatically_on_linking: false,
          });
        }
      },
      onError: () => {
        setUserPreferences({
          display_tips_modal_on_projects_list: true,
          display_tooltips: true,
          ai_model: 'model-1',
          image_model: 'image-1',
          ai_actions: false,
          create_pages_automatically_on_linking: false,
        });
      },
      errorMessage: 'Failed to fetch user preferences.',
      successMessage: 'User preferences fetched.',
    });

  const sendSupportMessage = (message: string) =>
    executeApiAction({
      action: () => ApiClient.sendSupportMessage(message),
      successMessage: 'Thank you for your message!',
      errorMessage: 'Failed to send message',
    });

    const deleteUser = async (id: string) => {
      await executeApiAction({
        action: async () => {
          const data = await ApiClient.deleteUser(id);
          console.log("Parsed API Client Response:", data); 
          return data; 
        },
        onSuccess: (data: { status: string }) => {
          console.log("test", data); 
          if (data.status === 'success') {
            toast({
              title: 'Account Deleted',
              description: 'Your account has been successfully deleted',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
    
            signOut();
          } else if (data.status === 'subscription_still_active_or_canceled_with_benefits') {
            toast({
              title: 'Failed to delete your account',
              description: 'Ensure all your subscription are canceled before deleting',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
            console.log('Cannot delete user account because subscription is still active or canceled with benefits.');
          }
        },
        errorMessage: 'Failed to delete user account',
        successMessage: 'User account deleted',
      });
    };
    const initFreeTrial = (userId: string, planId: string) =>
    executeApiAction({
      action: () => ApiClient.initFreeTrial(userId, planId),
      onSuccess: async () => {
        const user = await getMe();
        if (user) setUser(user);
        navigate('/');
      },
      successMessage: 'User FREE_TRIAL initialized.',
      errorMessage: 'User FREE_TRIAL initialization failed.',
    });

  return {
    register,
    authnPassword,
    socialAuth,
    signOut,
    getRememberInfo,
    resetPassword,
    verifyResetPassword,
    verifyUser,
    resendVerification,
    getMe,
    sendSupportMessage,
    updateUserPreferences,
    fetchUserPreferences,
    deleteUser,
    initFreeTrial,
  };
};