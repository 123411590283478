export const constructPageFormsArray = (formTags: any, pageId: string, defaultDestinationEmail: string) => {
  return Array.from(formTags).map((form: any) => {
      return {
          page_id: pageId,
          dom_id: form.getAttribute('id'),
          destination_email: defaultDestinationEmail,
          action: form.getAttribute('action') || '',
          is_active: form.getAttribute('action') && form.getAttribute('action').includes('/forms/handle-submission/') ? true : false,
          parent_id: form.parentNode?.id || form.parentNode?.parentNode?.id,
      }
  })
}

export const constructPageLinksArray = (pageAnchorTags: any) => {
    return Array.from(pageAnchorTags).map((a: any) => {
        const href = a.getAttribute('href');
        return {
            id: a.id,
            parent_id: a.parentNode?.id || a.parentNode?.parentNode?.id,
            href: href,
            inner: a.innerHTML,
            type: href?.endsWith('.html') ? 'internal' : 'external'
        }
    })
}

export const hasBackgroundImage = (element: any) => {
    var style = window.getComputedStyle(element);

    // if (element.tagName === 'SECTION') {

    //   console.log('style.backgroundImage: ', style.backgroundImage);
    //   // console.log('style.background: ', style.background);
      
    //   // console.log("style.getPropertyValue('background-image'): ", element.id, style.getPropertyValue('background-image'));
    //   // console.log("style.getPropertyValue('background'): ", element.id, style.getPropertyValue('background'));
    // }
    
    const backgroundImage = style.backgroundImage;

    if (backgroundImage && backgroundImage !== 'none' && backgroundImage.includes('url(')) {
        return true;
    }

    // const background = style.background;
    // console.log("background && background.includes('url('): ", background && background.includes('url('));
    // return background && background.includes('url(');
}


function findParentSection(element: any): any {
  if (element.parentElement && element.parentElement.id === 'body') {
    return element;
  } else if (element.parentElement) {
    return findParentSection(element.parentElement);
  } else {
    return null;
  }
}

export const constructPageImagesArray = (pageElements: any) => {
    console.log('pageElements: ', pageElements);

    return pageElements.map((element: any) => {
      if (!element) return;

      if (element.tagName === 'IMG') {
        
        if (element.src && element.getAttribute('src').includes("data:image/svg")) return null;
        
        return {
          id: element.id,
          parent_id: findParentSection(element) && findParentSection(element).id || element.parentNode.id || element.parentNode.parentNode.id || "body",
          // parent_id: element.parentNode.id || element.parentNode.parentNode.id,
          src: element.getAttribute('src'),
          type: 'url'
        };
      } else {
        var style = window.getComputedStyle(element)
        // Extracting URL from background image CSS
        const backgroundUrlRegex = /url\(['"]?(.*?)['"]?\)/;
        // const backgroundImage = element.style.backgroundImage || element.style.background;
        const backgroundImage = style.getPropertyValue('background-image');
  
        
        if (backgroundImage) {
          // console.log('backgroundImage: ', backgroundImage);
          const matches = backgroundUrlRegex.exec(backgroundImage);
          const url = matches ? matches[1] : null;

          if (url && url.includes("data:image/svg")) return null;

          return {
            id: element.id,
            parent_id: findParentSection(element) && findParentSection(element).id || element.parentNode.id || element.parentNode.parentNode.id,
            // parent_id: element.parentNode.id || element.parentNode.parentNode.id,
            src: url,
            type: 'background'
          };
        } else {
          return null;
        }
      }
    }).filter((image: any) => image !== null);
  };

  
// export const constructPageImagesArray = (pageImgTags: any) => {
//     return Array.from(pageImgTags).map((img: any) => {
//         return {
//             id: img.id,
//             parent_id: img.parentNode.id || img.parentNode.parentNode.id,
//             src: img.getAttribute('src'),
//             type: 'url'
//         }
//     })
// }

// export const constructPageLinksArray = (pageAmchorTags: any) => {
//     return Array.from(pageAmchorTags).map((a: any) => {
//         return {
//             id: a.id,
//             parent_id: a.parentNode.id || a.parentNode.parentNode.id,
//             href: a.getAttribute('href'),
//             inner: a.innerHTML,
//             type: a.getAttribute('href').endsWith('.html') ? 'internal' : 'external'
//         }
//     })
// }