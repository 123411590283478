import { useCallback } from "react";
import { useSetRecoilState } from 'recoil';
import { currentUserState, UserAllottedCredits } from '../state';
import { ApiClient } from "../lib/clients/api-client";
import { Page } from "../types";
import { useApiActions } from "./api-actions";

export const useFormActions = () => {
    const { executeApiAction } = useApiActions();
    const setUserAllottedCredits = useSetRecoilState(UserAllottedCredits);

    const enableForm = async ({
        userId,
        projectId,
        pageId,
        domId,
        destinationEmail,
    }: {
        userId: string;
        projectId: string;
        pageId: string;
        domId: string;
        destinationEmail: string;
    }) =>
        await executeApiAction({
            action: () =>
                ApiClient.enableForm({
                    userId,
                    projectId,
                    pageId,
                    domId,
                    destinationEmail,
                }),
            onSuccess: async () => {
                const { me } = await ApiClient.getMe();
                setUserAllottedCredits({
                    projects_allowed: me.projects_allowed,
                    sites_allowed: me.sites_allowed,
                    forms_allowed: me.forms_allowed,
                    exports_allowed: me.exports_allowed,
                    submissions_allowed: me.submissions_allowed,
                    bandwidth_allowed: me.bandwidth_allowed,
                    pages_allowed: me.pages_allowed,
                    translations_allowed: me.translations_allowed
                })
            },
            errorMessage: "Failed to enable form.",
            successMessage: "Form enabled successfully.",
        });

    const getUserForms = async (projectId: string) =>
        await executeApiAction({
            action: () => ApiClient.getUserForms(projectId),
            errorMessage: 'Failed to fetch forms.',
            successMessage: 'Forms fetched successfully.',
        });

    const getProjectForms = async (projectId: string) =>
        await executeApiAction({
            action: () => ApiClient.getProjectForms(projectId),
            errorMessage: 'Failed to fetch forms.',
            successMessage: 'Forms fetched successfully.',
        });

    const toggleFormStatus = async (formId: string) =>
        await executeApiAction({
            action: () => ApiClient.toggleFormStatus(formId),
            onSuccess: async () => {
                const { me } = await ApiClient.getMe();
                setUserAllottedCredits({
                    projects_allowed: me.projects_allowed,
                    sites_allowed: me.sites_allowed,
                    forms_allowed: me.forms_allowed,
                    exports_allowed: me.exports_allowed,
                    submissions_allowed: me.submissions_allowed,
                    bandwidth_allowed: me.bandwidth_allowed,
                    pages_allowed: me.pages_allowed,
                    translations_allowed: me.translations_allowed
                })
            },
            errorMessage: 'Failed to update form.',
            successMessage: 'Form updated successfully.',
        });

    const deleteForm = async (formId: string) =>
        await executeApiAction({
            action: () => ApiClient.deleteForm(formId),
            onSuccess: async () => {
                const { me } = await ApiClient.getMe();
                setUserAllottedCredits({
                    projects_allowed: me.projects_allowed,
                    sites_allowed: me.sites_allowed,
                    forms_allowed: me.forms_allowed,
                    exports_allowed: me.exports_allowed,
                    submissions_allowed: me.submissions_allowed,
                    bandwidth_allowed: me.bandwidth_allowed,
                    pages_allowed: me.pages_allowed,
                    translations_allowed: me.translations_allowed
                })
            },
            errorMessage: 'Failed to delete form.',
            successMessage: 'Form deleted successfully.',
        });

    return {
        enableForm: useCallback(enableForm, [
            executeApiAction,
        ]),
        getUserForms: useCallback(getUserForms, [executeApiAction]),
        getProjectForms: useCallback(getProjectForms, [executeApiAction]),
        toggleFormStatus: useCallback(toggleFormStatus, [executeApiAction]),
        deleteForm: useCallback(deleteForm, [executeApiAction]),
    };
};
