import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import { ApiClient } from '../lib/clients/api-client';
import { IsUploadingSpinner, IsDeployingSpinner, IsTranscribingSpinner, IsGeneratingImageSpinner, IsGeneratingLoadingScreen, IsGeneratingSpinner, SelectedProjectMode, projectsState, DefaultProjectSourceFile, selectedProjectSelector, pagesState, selectedPageSelector, ViewportSourceDocument, ChatBubbles, ContentUndoHistory, ConversationHistoryUndoHistory, UndoHistoryStepIndex, HTMLCode, CSSCode, JavaScriptCode, LocalContext, ConversationHistory, currentUserState, ViewportTree, PageLinks, PageImages, OutlinerTree, LocalContextCSS, PageForms, GlobalContextOuterHTML } from '../state';
import { Project, Page } from '../types';
import { useApiActions } from './api-actions';
import { usePageActions } from './';

export const useProjectActions = () => {

  // I need currentUser here...

  const setUser = useSetRecoilState(currentUserState);

  const navigate = useNavigate();
  const [projects, setProjects] = useRecoilState(projectsState);
  const [selectedProject, setSelectedProject] = useRecoilState(selectedProjectSelector);
  const { executeApiAction } = useApiActions();
  const { createPage, fetchPageContent, fetchConversationHistory, fetchChat, fetchLinks, fetchForms, fetchImages } = usePageActions();
  const setPages = useSetRecoilState(pagesState);
  const setSelectedPage = useSetRecoilState(selectedPageSelector);
  const setViewportSourceDocument = useSetRecoilState(ViewportSourceDocument);
  const resetChatBubbles = useResetRecoilState(ChatBubbles);

  const setConversationHistory = useSetRecoilState(ConversationHistory);
  const setContentUndoHistory = useSetRecoilState(ContentUndoHistory);
  const setConversationHistoryUndoHistory = useSetRecoilState(ConversationHistoryUndoHistory);
  const setPageLinks = useSetRecoilState(PageLinks);

  const resetUndoHistoryStepIndex = useResetRecoilState(UndoHistoryStepIndex);
  const resetLocalContext = useResetRecoilState(LocalContext);
  const resetLocalContextCSS = useResetRecoilState(LocalContextCSS);
  const resetViewportTree = useResetRecoilState(ViewportTree);
  const resetGlobalContextOuterHTML = useResetRecoilState(GlobalContextOuterHTML);
  const resetOutlinerTree = useResetRecoilState(OutlinerTree);
  const resetPageLinks = useResetRecoilState(PageLinks);
  const resetPageForms = useResetRecoilState(PageForms);
  const resetPageImages = useResetRecoilState(PageImages);


  const resetIsGeneratingSpinner = useResetRecoilState(IsGeneratingSpinner);
  const resetIsGeneratingLoadingScreen = useResetRecoilState(IsGeneratingLoadingScreen);
  const resetIsGeneratingImageSpinner = useResetRecoilState(IsGeneratingImageSpinner);
  const resetIsTranscribingSpinner = useResetRecoilState(IsTranscribingSpinner);
  const resetIsDeployingSpinner = useResetRecoilState(IsDeployingSpinner);
  const resetIsUploadingSpinner = useResetRecoilState(IsUploadingSpinner);

  const resetHTMLCode = useResetRecoilState(HTMLCode);
  const resetCSSCode = useResetRecoilState(CSSCode);
  const resetJavaScriptCode = useResetRecoilState(JavaScriptCode);

  const setSelectedProjectMode = useSetRecoilState(SelectedProjectMode);
  const setDefaultProjectSourceFile = useSetRecoilState(DefaultProjectSourceFile);

  const setHTMLCode = useSetRecoilState(HTMLCode);
  const setCSSCode = useSetRecoilState(CSSCode);
  const setJavaScriptCode = useSetRecoilState(JavaScriptCode);

  const getProject = async (id: string) =>
    await executeApiAction<Project>({
      action: () => ApiClient.getProject(id),
      onError: () => navigate('/'),
      errorMessage: 'Failed to load project, refresh to try again',
    });

  const createProject = async ({ name, selectedProjectMode, favicon, fromTemplate }: { name: string, selectedProjectMode: string, favicon: string, fromTemplate: boolean }) =>
    await executeApiAction({
      action: () => ApiClient.createProject({ name, selectedProjectMode, favicon, fromTemplate }),
      onSuccess: async ({ project, source_file }: any) => {
        
        if (!fromTemplate) {
          setDefaultProjectSourceFile(source_file);
  
          setViewportSourceDocument(
            source_file
          );

          resetChatBubbles();
  
          setContentUndoHistory([
            {
              timestamp: new Date().toLocaleString(),
              label: 'Initial',
              content: source_file
            },
          ]);

          resetUndoHistoryStepIndex();
          resetLocalContext();
          resetLocalContextCSS();
          resetViewportTree();
          resetGlobalContextOuterHTML();
          resetOutlinerTree();
          resetPageLinks();
          resetPageForms();
          resetPageImages();
  
          resetHTMLCode();
          resetCSSCode();
          resetJavaScriptCode();

        }



        setProjects([project, ...projects]);
        setSelectedProject(project);
        setSelectedProjectMode(project.mode);

        // Will probably construct half of messages on the front-end,
        // and the second half on the backend,
        // and once again, conversation history was used for context, it is no longer needed.

        if (fromTemplate) {
          fetchProjectPages(project.id);
        } else {
          createPage({
            projectId: project.id, title: 'Home', path: 'index.html', content: source_file,
            // projectId: project.id, title: 'Home', content: (projectModes as any)[project.mode].source,
            conversationHistory: [
              {
                role: 'system',
                content: ''
                // content: (projectModes as any)[project.mode].systemMessage
              },
            ],
            chat: [
              {
                date: Date.now(),
                type: 'message',
                source: 'assistant',
                content: "Hi! SIFO here. Let's create cool stuff together!"
              }
            ],
            links: [],
            images: [],
            forms: [],
            locale: 'Default',
            isIndex: true
          });
        }

        // This needs to not go lower than 0.
        // setUser((previousUser: any) => {
        //   return {
        //     ...previousUser,
        //     projects_allowed: previousUser.projects_allowed - 1
        //   }
        // })


        // RESETS
        if (!fromTemplate) {
          resetIsGeneratingSpinner();
          resetIsGeneratingLoadingScreen();
          resetIsGeneratingImageSpinner();
          resetIsTranscribingSpinner();
          resetIsDeployingSpinner();
          resetIsUploadingSpinner();
        }

        // Useup credits, get user back, set user
        const { me } = await ApiClient.getMe();
        setUser(me);

        navigate(`/projects/${project.id}/dashboard`);
      },
      errorMessage: 'Failed to create project',
      successMessage: 'Project created',
    });

  const updateProject = async (data: Partial<Project>) =>
    await executeApiAction({
      action: () => ApiClient.updateProject(data),
      onSuccess: (project: Project) => {
        if (project.id === selectedProject?.id) {
          setSelectedProject(project);
        }
        setProjects(projects.map((p) => (p.id === project.id ? project : p)));
      },
      errorMessage: 'Failed to update project',
      successMessage: 'Project saved',
    });

  const deleteProject = async (id: string) =>
    await executeApiAction({
      action: () => ApiClient.deleteProject(id),
      onSuccess: () => {
        setProjects(projects.filter((p) => p.id !== id));
        if (selectedProject?.id === id) {
          setSelectedProject(null);
        }

        navigate('/');
      },
      errorMessage: 'Failed to delete project',
      successMessage: 'Project deleted',
    });

  const fetchProjectPages = async (project_id: string) =>
    await executeApiAction({
      action: () => ApiClient.fetchProjectPages(project_id),
      onSuccess: (pages: Page[]) => {

        const pagesCopy = [...pages];
        pagesCopy.sort((a: any, b: any) => b.is_index - a.is_index);

        setPages(pagesCopy);
        setSelectedPage(pagesCopy[0]);


        // resetChatBubbles();

        setContentUndoHistory([
          {
            timestamp: new Date().toLocaleString(),
            label: 'Initial',
            // content: ''
            // content: pagesCopy[0].content
            content: ` ${pagesCopy[0].content} `
          },
        ]);

        setConversationHistoryUndoHistory([
          pagesCopy[0].conversation_history
        ])

        resetUndoHistoryStepIndex();
        resetLocalContext();
        resetLocalContextCSS();
        resetViewportTree();
        resetGlobalContextOuterHTML();
        resetOutlinerTree();
        resetPageLinks();
        resetPageForms();
        resetPageImages();

        const parser = new DOMParser();
        let pageDocument = parser.parseFromString(pagesCopy[0].content, 'text/html');

        const bodyElement = pageDocument.querySelector('div#body');
        const styleElement = pageDocument.querySelector('style[data-dev-tag="inject-into"]');
        const scriptElement = pageDocument.querySelector('script[data-dev-tag="inject-into"]');

        if (bodyElement) setHTMLCode(bodyElement.innerHTML);
        if (styleElement) setCSSCode(String(styleElement.textContent));
        if (scriptElement) setJavaScriptCode(scriptElement.innerHTML);


        fetchPageContent(pagesCopy[0].id);
        fetchConversationHistory(pagesCopy[0].id);
        fetchChat(pagesCopy[0].id);
        fetchLinks(pagesCopy[0].id);
        fetchForms(pagesCopy[0].id);
        fetchImages(pagesCopy[0].id);

        resetIsGeneratingSpinner();
        resetIsGeneratingLoadingScreen();
        resetIsGeneratingImageSpinner();
        resetIsTranscribingSpinner();
        resetIsDeployingSpinner();
        resetIsUploadingSpinner();
      },
      errorMessage: 'Failed to fetch project pages.',
      successMessage: 'Project pages fetched.',
    });
  return {
    getProject: useCallback(getProject, [executeApiAction, navigate]),
    createProject: useCallback(createProject, [setProjects, setSelectedProject, executeApiAction, navigate, projects, createPage, resetCSSCode, resetChatBubbles, resetHTMLCode, resetJavaScriptCode, resetLocalContext, resetUndoHistoryStepIndex, setViewportSourceDocument, setContentUndoHistory, setConversationHistory, setConversationHistoryUndoHistory, setSelectedProjectMode]),
    updateProject: useCallback(updateProject, [
      setProjects,
      setSelectedProject,
      selectedProject,
      executeApiAction,
      projects,
    ]),
    deleteProject: useCallback(deleteProject, [
      setProjects,
      setSelectedProject,
      selectedProject,
      executeApiAction,
      projects,
      navigate,
    ]),
    fetchProjectPages: useCallback(fetchProjectPages, [executeApiAction, fetchConversationHistory, fetchChat, fetchPageContent, resetChatBubbles, resetLocalContext, resetUndoHistoryStepIndex, setCSSCode, setContentUndoHistory, setConversationHistoryUndoHistory, setHTMLCode, setJavaScriptCode, setPages, setSelectedPage]),
  };
};