import React, { useState, useEffect, useRef } from 'react';
import { Renderer } from './renderer';
import { WillRepairJavascript, IsGlowingEnhance, Autofixer, SelectedProjectMode, IsGeneratingSpinner, ConversationHistory, ChatBubbles, selectedPageSelector, ConversationHistoryUndoHistory, LocalContext, LocalContextOuterHTML, UserPreferences, ViewportTree, GlobalContextOuterHTML, TranscriptionOutput, IsGeneratingLoadingScreen, currentUserState, iconDisabledState } from '../../state';
import { useRecoilState, useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { usePageActions, useOpenaiActions, useSIFOActions } from '../../hooks';
import { cssjs } from 'jotform-css.js';
import { writeCSSFromRules } from './css-tools';
import { completionParser } from './completion-parser';
import { TriggeredFunction } from '../../state/function-calling';
import { useDisclosure } from '@chakra-ui/react';
import { normalizeSelector } from './css-tools';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Button,
  Icon,
  Text,
  useToast,
  Tooltip
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { retryWithModel2, minimalStylingDetection, aiActionsFunctionCalling } from '../../lib/feature-availability';
// import { parse, stringify } from 'yaml'
import AWS from 'aws-sdk';

// import { CompletionLoadingScreen } from './completion-loading-screen';
import { useNavigate } from 'react-router-dom';

import { projectBootstrapTemplatesAsLowerCaseStrings } from '../../lib/project-modes/bootstrap-templates';
export const Processor: React.FC<any> = ({ setAreIconsDisabled, transcription, enhanced, handleClickInProcessorWhenMobile, isEnhanceCoolingDown }) => {
  const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();
  const cancelRef = useRef<any | null>();
  const navigate = useNavigate();
  const MotionBox = motion(Box);
  const toast = useToast();
  const setIconDisabled = useSetRecoilState(iconDisabledState);

  const isInitialMount = useRef(true);

  // const [willRepairJavascript, setWillRepairJavascript] = useRecoilState(WillRepairJavascript);
  const setWillRepairJavascript = useSetRecoilState<any>(WillRepairJavascript);

  const resetTranscriptionOutput = useResetRecoilState(TranscriptionOutput);

  // const { isOpen: isCompletionLoadScreenOpen, onOpen: onCompletionLoadScreenOpen, onClose: onCompletionLoadScreenClose } = useDisclosure();

  const [objects, setObjects] = useState<any[]>([]);

  const setIsGlowingEnhance = useSetRecoilState<any>(IsGlowingEnhance);
  const setAutofixer = useSetRecoilState<any>(Autofixer);
  const [chatBubbles, setChatBubbles] = useRecoilState(ChatBubbles);
  const [localContext, setLocalContext] = useRecoilState(LocalContext);
  const [conversationHistory, setConversationHistory] = useRecoilState(ConversationHistory);


  const [isGeneratingSpinner, setIsGeneratingSpinner] = useRecoilState(IsGeneratingSpinner);
  // const setIsGeneratingSpinner = useSetRecoilState(IsGeneratingSpinner);
  const setIsGeneratingLoadingScreen = useSetRecoilState(IsGeneratingLoadingScreen);
  const setConversationHistoryUndoHistory = useSetRecoilState(ConversationHistoryUndoHistory);
  const setTriggeredFunction = useSetRecoilState(TriggeredFunction);

  const user = useRecoilValue(currentUserState);
  const selected_page = useRecoilValue(selectedPageSelector);
  const localContextOuterHTML = useRecoilValue(LocalContextOuterHTML);
  const selectedProjectMode = useRecoilValue(SelectedProjectMode);
  const userPreferences = useRecoilValue(UserPreferences);
  const viewportTree = useRecoilValue(ViewportTree);
  const globalContextOuterHTML = useRecoilValue(GlobalContextOuterHTML);

  const { updatePageConversationHistory, updateChat } = usePageActions();
  const { triggerFunctionCall, getCompletions, fetchUnsplash } = useOpenaiActions();


  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:98689dd4-f972-4f76-8aec-0b54f0cb2c08'
    }),
    httpOptions: {
      timeout: 600000 
    }
  });
  const lambda = new AWS.Lambda();

  let lambdaFunctionName : any

  if (window.location.hostname === 'app.sifo.ai') {
    lambdaFunctionName = 'my-app-api-production-completion'
  } else if (window.location.hostname === 'app.staging.sifo.ai') {
    lambdaFunctionName = 'my-app-api-staging-completion'
  }
  // const extractJSONArray = (str: string) => {
  //   let openIndex = -1;
  //   let closeIndex = -1;

  //   for (let i = 0; i < str.length; i++) {
  //     if (str[i] === '[' || str[i] === '{') {
  //       openIndex = i;
  //       break;
  //     }
  //   }

  //   if (openIndex === -1) {
  //     return "";
  //   }

  //   for (let i = str.length - 1; i >= 0; i--) {
  //     if (str[i] === ']' || str[i] === '}') {
  //       closeIndex = i;
  //       break;
  //     }
  //   }

  //   if (closeIndex === -1 || closeIndex < openIndex) {
  //     return "";
  //   }

  //   return str.substring(openIndex, closeIndex + 1);
  // }

  const extractImageGenerationKeywordFromURL = (url: string) => {
    const regex = /\?([A-Za-z0-9\-_&]*)/;
    const matches = url.match(regex);

    if (matches && matches.length > 1) {
      return matches[1];
    } else return null;
  }

  const extractAndSanitizeSelector = (selector: string) => {
    if (selector) {
      const segments = selector.split(/[^a-zA-Z0-9-]/);
      const cleanedInput = segments[segments.length - 1];

      const finalInput = cleanedInput.replace(/^-+|-+$/g, '');

      const validSelector = normalizeSelector(finalInput);
      return validSelector;
    }

    return '';
  }

  // const extractAndSanitizeSelector = (selector: string) => {
  //   if (selector) {
  //     const cleanedInput = selector.replace(/[^a-zA-Z0-9-]/g, '');
  //     const finalInput = cleanedInput.replace(/^-+|-+$/g, '');
  //     const validSelector = normalizeSelector(finalInput); // Fix it if it starts with a number.

  //     // const validSelector = finalInput.match(/^[0-9]/) ? `_${finalInput}` : finalInput;


  //     return validSelector;
  //   }

  //   return '';
  // }

  const postprocessHTMLElement = async (element: any) => {
    if (!element.attributes)
      element.attributes = {};

    if (!element.attributes.id) {
      const tag = element.tag || 'unknown';
      const randomId = Math.floor(Math.random() * 1000000);
      element.attributes.id = `${tag}-${randomId}`;
    }

    element.attributes.id = extractAndSanitizeSelector(element.attributes.id);
    element.is_before = extractAndSanitizeSelector(element.is_before);
    element.is_after = extractAndSanitizeSelector(element.is_after);
    element.parent = extractAndSanitizeSelector(element.parent);

    if (element.attributes.style) {
      element.attributes.style = await postprocessCSSCode(element.attributes.style);
    }

    if (element.tag && element.tag.toLowerCase() === 'img') {
      if (element.attributes.src.indexOf('source.unsplash.com') !== -1) {
        const imageSearchKeyword = extractImageGenerationKeywordFromURL(element.attributes.src);
        if (imageSearchKeyword) {
          try {
            const data: any = await fetchUnsplash(imageSearchKeyword, null);

            if (data.results && data.results.length > 0) {
              const fetchedImage = data.results[0];
              element.attributes.src = fetchedImage.urls.regular || fetchedImage.urls.full || fetchedImage.urls.raw;
            } else {
              throw new Error('No image found');
            }
          } catch (error) {
            element.attributes.src = "#";
            console.error("Failed to fetch image URL from Unsplash:", error);
          }
        }
      }
    }


    const { children } = element;
    if (!children) return;

    await Promise.all(
      children.map(async (child: any) => {
        await postprocessHTMLElement(child);
      })
    )
  }

  const postprocessCSSCode = async (code: string) => {
    const css_parser = new cssjs();
    const parsed = css_parser.parseCSS(code);

    const altered = await Promise.all(
      (parsed as any).map(async (selector: any) => {
        if (!selector.rules) {
          return selector;
        }

        const processedRules = await Promise.all(
          selector.rules.map(async (rule: any) => {
            if (rule.value && rule.value.startsWith('url') && rule.value.indexOf('source.unsplash.com') !== -1) {
              const keyword = extractImageGenerationKeywordFromURL(rule.value);
              if (!keyword) return rule;
              try {
                // const response = await fetch(`https://source.unsplash.com/featured/1280x720/?${keyword}`);
                // if (response.ok) {
                //   rule.value = `url("${response.url}")`;
                // }

                const data: any = await fetchUnsplash(keyword, null);

                if (data.results && data.results.length > 0) {
                  const fetchedImage = data.results[0];
                  rule.value = `url("${fetchedImage.urls.regular || fetchedImage.urls.full || fetchedImage.urls.raw}")`;
                } else {
                  throw new Error('No image found');
                }
              } catch (error) {
                rule.value = `url("#")`;
                console.error("Failed to fetch image URL from Unsplash:", error);
              }

              return rule;
            }

            return rule;
          })
        );

        return {
          selector: selector.selector,
          rules: processedRules,
        };
      })
    );

    console.log('writeCSSFromRules(altered) || code: ', writeCSSFromRules(altered) || code);
    return writeCSSFromRules(altered) || code;
  }


  const postprocessUsableJSON = async (obj: any) => {
    if (obj.type === 'html') {
      const { element } = obj;
      if (!element) return;

      await postprocessHTMLElement(element);
    }

    if (obj.type === 'css') {
      const { code } = obj;
      if (!code) return;

      obj.code = await postprocessCSSCode(code);
    }
  }

  const wait = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // interface ElementStructure {
  //   tag: string;
  //   attributes: { [key: string]: string };
  //   text: string;
  //   parent: string;
  //   children: ElementStructure[];
  // }

  // interface JsonOutput {
  //   type: string;
  //   element?: ElementStructure;
  //   code?: string;
  //   dependencies?: string[];
  // }

  // interface InputFormat {
  //   html: string;
  //   css: string;
  //   js: string;
  //   unknown: string;
  // }

  // function convertToJson(input: InputFormat): JsonOutput[] {
  //   const result: JsonOutput[] = [];

  //   // Process HTML
  //   if (input.html) {
  //     const parser = new DOMParser();
  //     const doc = parser.parseFromString(input.html, 'text/html');

  //     const processElement = (element: Element, parentId: string = 'body'): ElementStructure => {
  //       const result: ElementStructure = {
  //         tag: element.tagName.toLowerCase(),
  //         attributes: {},
  //         text: '',
  //         parent: parentId,
  //         children: []
  //       };

  //       Array.from(element.attributes).forEach(attr => {
  //         result.attributes[attr.name] = attr.value;
  //       });

  //       let textContent = '';
  //       Array.from(element.childNodes).forEach(child => {
  //         if (child.nodeType === Node.ELEMENT_NODE) {
  //           result.children.push(processElement(child as Element, result.attributes.id || parentId));
  //         } else if (child.nodeType === Node.TEXT_NODE) {
  //           const trimmedText = child.textContent?.trim() || '';
  //           if (trimmedText) {
  //             textContent += (textContent ? ' ' : '') + trimmedText;
  //           }
  //         }
  //       });

  //       result.text = textContent;

  //       return result;
  //     }

  //     const rootElement = doc.body.firstElementChild;
  //     const htmlStructure = rootElement ? processElement(rootElement, rootElement.getAttribute("data-parent") || undefined) : undefined;

  //     result.push({
  //       type: 'html',
  //       element: htmlStructure
  //     });
  //   }

  //   // Process CSS
  //   if (input.css) {
  //     result.push({
  //       type: 'css',
  //       code: input.css
  //     });
  //   }

  //   // Process JavaScript
  //   if (input.js) {
  //     result.push({
  //       type: 'javascript',
  //       code: input.js,
  //       dependencies: [] // You can add logic to extract dependencies if needed
  //     });
  //   }

  //   // Process unknown (if needed)
  //   if (input.unknown) {
  //     result.push({
  //       type: 'unknown',
  //       code: input.unknown
  //     });
  //   }

  //   return result;
  // }

  // Broken
  // interface ElementStructure {
  //   tag: string;
  //   attributes: { [key: string]: string };
  //   text: string;
  //   parent: string;
  //   children: ElementStructure[];
  // }

  // interface JsonOutput {
  //   type: string;
  //   elements?: ElementStructure[];
  //   code?: string;
  //   dependencies?: string[];
  // }

  // interface InputFormat {
  //   html: string;
  //   css: string;
  //   js: string;
  //   unknown: string;
  // }

  // function convertToJson(input: InputFormat): JsonOutput[] {
  //   const result: JsonOutput[] = [];

  //   // Process HTML
  //   if (input.html) {
  //     const parser = new DOMParser();
  //     const doc = parser.parseFromString(`<div id="root">${input.html}</div>`, 'text/html');

  //     const processElement = (element: Element, parentId: string = 'root'): ElementStructure => {
  //       const result: ElementStructure = {
  //         tag: element.tagName.toLowerCase(),
  //         attributes: {},
  //         text: '',
  //         parent: parentId,
  //         children: []
  //       };

  //       Array.from(element.attributes).forEach(attr => {
  //         result.attributes[attr.name] = attr.value;
  //       });

  //       let textContent = '';
  //       Array.from(element.childNodes).forEach(child => {
  //         if (child.nodeType === Node.ELEMENT_NODE) {
  //           result.children.push(processElement(child as Element, result.attributes.id || parentId));
  //         } else if (child.nodeType === Node.TEXT_NODE) {
  //           const trimmedText = child.textContent?.trim() || '';
  //           if (trimmedText) {
  //             textContent += (textContent ? ' ' : '') + trimmedText;
  //           }
  //         }
  //       });

  //       result.text = textContent;

  //       return result;
  //     }

  //     const rootElement = doc.getElementById('root');
  //     const htmlStructures: ElementStructure[] = [];

  //     if (rootElement) {
  //       Array.from(rootElement.children).forEach(child => {
  //         const parentId = (child as Element).getAttribute('data-parent') || undefined;
  //         htmlStructures.push(processElement(child as Element, parentId));
  //       });
  //     }

  //     result.push({
  //       type: 'html',
  //       elements: htmlStructures
  //     });
  //   }

  //   // Process CSS
  //   if (input.css) {
  //     result.push({
  //       type: 'css',
  //       code: input.css
  //     });
  //   }

  //   // Process JavaScript
  //   if (input.js) {
  //     result.push({
  //       type: 'javascript',
  //       code: input.js,
  //       dependencies: [] // You can add logic to extract dependencies if needed
  //     });
  //   }

  //   // Process unknown (if needed)
  //   if (input.unknown) {
  //     result.push({
  //       type: 'unknown',
  //       code: input.unknown
  //     });
  //   }

  //   return result;
  // }


  /* -------------------------------------------------------------------- */

  interface ElementStructure {
    tag: string;
    attributes: { [key: string]: string };
    text: string;
    parent: string;
    is_before: string;
    is_after: string;
    children: ElementStructure[];
  }

  interface JsonOutput {
    type: string;
    element?: ElementStructure;
    code?: string;
    dependencies?: string[];
  }

  interface InputFormat {
    html: string;
    css: string;
    js: string;
    unknown: string;
  }

  function convertToJson(input: InputFormat): JsonOutput[] {
    const result: JsonOutput[] = [];
    let scriptsInHtml: any = "";

    // Process HTML
    if (input.html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(`<div id="root">${input.html}</div>`, 'text/html');

      const processElement = (element: Element, parentId: string = 'body'): ElementStructure => {

        const result: ElementStructure = {
          tag: element.tagName.toLowerCase(),
          attributes: {},
          text: '',
          parent: parentId,
          is_before: element.getAttribute("data-is-before") || "",
          is_after: element.getAttribute("data-is-after") || "",
          children: []
        };

        Array.from(element.attributes).forEach(attr => {
          if (attr.name === "data-parent") return;
          if (attr.name === "data-is-after") return;
          if (attr.name === "data-is-before") return;

          result.attributes[attr.name] = attr.value;
        });

        let textContent = '';
        Array.from(element.childNodes).forEach(child => {
          if (child.nodeType === Node.ELEMENT_NODE) {
            result.children.push(processElement(child as Element, result.attributes.id || parentId));
          } else if (child.nodeType === Node.TEXT_NODE) {
            const trimmedText = child.textContent?.trim() || '';
            if (trimmedText) {
              textContent += (textContent ? ' ' : '') + trimmedText;
            }
          }
        });

        result.text = textContent;

        return result;
      }

      const rootElement = doc.getElementById('root');

      if (rootElement) {
        Array.from(rootElement.children).forEach(child => {

          if (child.tagName === "SCRIPT") {
            scriptsInHtml += child.innerHTML;
            return;
          }

          const parentId = (child as Element).getAttribute('data-parent') || undefined;
          const htmlStructure = processElement(child as Element, parentId);
          result.push({
            type: 'html',
            element: htmlStructure
          });
        });
      }
    }

    // Process CSS
    if (input.css) {
      result.push({
        type: 'css',
        code: input.css
      });
    }

    // Process JavaScript
    if (input.js) {
      result.push({
        type: 'javascript',
        code: input.js,
        dependencies: [] // You can add logic to extract dependencies if needed
      });
    }

    // Process Scripts in HTML
    if (scriptsInHtml) {
      result.push({
        type: 'javascript',
        code: scriptsInHtml,
        dependencies: [] // You can add logic to extract dependencies if needed
      });
    }

    // Process unknown (if needed)
    if (input.unknown) {
      result.push({
        type: 'unknown',
        code: input.unknown
      });
    }

    return result;
  }

  /* -------------------------------------------------------------------- */

  type ContentType = 'html' | 'css' | 'js' | 'unknown';

  function splitAndAnalyzeContent(content: string): { [key in ContentType]: string } {
    const parts = content.split('@@@');
    const result: { [key in ContentType]: string } = {
      html: '',
      css: '',
      js: '',
      unknown: ''
    };

    parts.forEach(part => {
      const trimmedPart = part.trim();
      const contentType = determineContentType(trimmedPart);
      result[contentType] = trimmedPart;
    });

    return result;
  }

  function determineContentType(content: string): ContentType {
    // Check for HTML first
    if (/<[a-z]+[\s>]/i.test(content)) {
      return 'html';
    }

    const htmlCharCount = (content.match(/[<>]/g) || []).length;
    const cssCharCount = (content.match(/[:.#{}]/g) || []).length;
    const jsCharCount = (content.match(/[;=()]/g) || []).length;

    const totalChars = content.length;
    const htmlRatio = htmlCharCount / totalChars;
    const cssRatio = cssCharCount / totalChars;
    const jsRatio = jsCharCount / totalChars;

    const threshold = 0.005;

    if (htmlRatio > threshold && htmlRatio > cssRatio && htmlRatio > jsRatio) {
      return 'html';
    } else if (cssRatio > threshold && cssRatio > htmlRatio && cssRatio > jsRatio) {
      return 'css';
    } else if (jsRatio > threshold && jsRatio > htmlRatio && jsRatio > cssRatio) {
      return 'js';
    } else {
      return 'unknown';
    }
  }

  // interface ElementStructure {
  //   tag: string;
  //   attributes: { [key: string]: string };
  //   text: string;
  //   parent: string;
  //   children: ElementStructure[];
  // }

  // interface JsonOutput {
  //   type: string;
  //   element?: ElementStructure;
  //   code?: string;
  //   dependencies?: string[];
  // }

  // function convertHtmlToJson(htmlString: string): JsonOutput[] {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, 'text/html');

  //   function processElement(element: Element, parentId: string = 'body'): ElementStructure {
  //     const result: ElementStructure = {
  //       tag: element.tagName.toLowerCase(),
  //       attributes: {},
  //       text: '',
  //       parent: parentId,
  //       children: []
  //     };

  //     Array.from(element.attributes).forEach(attr => {
  //       result.attributes[attr.name] = attr.value;
  //     });

  //     let textContent = '';
  //     Array.from(element.childNodes).forEach(child => {
  //       if (child.nodeType === Node.ELEMENT_NODE) {
  //         result.children.push(processElement(child as Element, result.attributes.id || parentId));
  //       } else if (child.nodeType === Node.TEXT_NODE) {
  //         const trimmedText = child.textContent?.trim() || '';
  //         if (trimmedText) {
  //           textContent += (textContent ? ' ' : '') + trimmedText;
  //         }
  //       }
  //     });

  //     result.text = textContent;

  //     return result;
  //   }

  //   const rootElement = doc.body.firstElementChild;
  //   const htmlStructure = rootElement ? processElement(rootElement) : null;

  //   const result: JsonOutput[] = [
  //     {
  //       type: 'html',
  //       element: htmlStructure || undefined
  //     },
  //     {
  //       type: 'css',
  //       code: ''
  //     },
  //     {
  //       type: 'javascript',
  //       dependencies: [''],
  //       code: ''
  //     }
  //   ];

  //   return result;
  // }

  const runChatCompletion = async (prompt: string, options?: any) => {

    const fromTemplate = projectBootstrapTemplatesAsLowerCaseStrings.includes(selectedProjectMode.toLocaleLowerCase());

    let timer;
    let payload: any;

    let firstCompletion = false;
    let notInIndex = false;

    let addNewPageContextHelperPrompt = false;

    let currentContext;

    if (fromTemplate) {
      currentContext = globalContextOuterHTML;
    } else {
      currentContext = viewportTree;
    }

    console.log('Current Context: ', currentContext);
    console.log("typeeeeeeeeeeeee: ", options);


    // Do my check here.
    // if i'm on the first completion, viewport is empty, for instance, or current page
    // if it's not an index, any page other than index,

    if (selected_page?.chat.length === 1) {
      firstCompletion = true;
    }

    if (selected_page?.is_index === false) {
      notInIndex = true;
    }

    if (firstCompletion && notInIndex) {
      addNewPageContextHelperPrompt = true;
    }

    // :: Key is to get current page, then use it for both checks. ::

    // Produce the flag I'm going to send to the backend.

    let maxAttempts: number = 3;

    if (userPreferences.ai_model === "model-1") {
      // if GPT
      // if planID set retries to 2, if not to 2
      if (retryWithModel2.includes(user?.plan_id as string)) {
        maxAttempts = 3;
      } else {
        maxAttempts = 1;
      }
    } else if (userPreferences.ai_model === "model-2" || userPreferences.ai_model === "model-3" || userPreferences.ai_model === "model-4") {
      maxAttempts = 3
    }

    let currentAttempt = 1;

    while (currentAttempt <= maxAttempts) {

      // This might not be necessary, but I remember needing it,
      // The first time I implemented retry logic.

      if (currentAttempt !== 1) await wait(2500);

      try {
        // Loading screen,

        timer = setTimeout(() => {
          setIsGeneratingLoadingScreen(true);
        }, 6000);

        // Create messages depending on model,
        // output being usableJSON, previously.

        let messages: any = null;
        let output: any;

        let modelToUse = userPreferences.ai_model;

        if (modelToUse === 'model-1' && (currentAttempt === 2 || currentAttempt === 3)) {
          modelToUse = "model-2";
        } else {
          modelToUse = userPreferences.ai_model;
        }
        if (user?.plan_id === 'FREE_TRIAL') {
          modelToUse = 'model-2';
        } // setting model to use free trial to haiku even if it shows as model -1 so they think the wost is high quality

        // Override model to use when fixing javascript
        if (options && options.temporarySystemMessage && options.temporarySystemMessage.enable === true) {
          if (modelToUse === "model-1") {
            modelToUse = "model-2";
          }

          if (modelToUse === "model-2") {
            modelToUse = "model-2";
          }

          if (modelToUse === "model-3") {
            modelToUse = "model-2";
          }

          if (modelToUse === "model-4") {
            modelToUse = "model-4";
          }
        }

        if (modelToUse === 'sifo-0') {
          // GPT-4 Turbo (Legacy)

          messages = localContext ? [
            { role: 'user', content: `You are editing the following element: ${localContextOuterHTML}, \n ${prompt}` }
          ] : [
            {
              role: 'assistant', content: currentContext ? JSON.stringify(
                {
                  response: currentContext
                }
              ) : ''
            },
            { role: 'user', content: prompt }
          ];

        } else if (modelToUse === 'model-1') {
          // GPT-3.5 Turbo

          messages = localContext ? [
            { role: 'user', content: `You are editing the following element: ${localContextOuterHTML}, \n ${prompt}` }
          ] : [
            { role: 'assistant', content: currentContext },
            { role: 'user', content: prompt }
          ];

        } else if (modelToUse === 'model-2') {
          // Haiku

          let initialUserMessage = { role: 'user', content: "You only output code, do not speak to me in any way except outputing code for my request." };

          messages = localContext ? [
            initialUserMessage,
            { role: 'assistant', content: `You are editing the following element: ${localContextOuterHTML}` },
            { role: 'user', content: prompt }
          ] : [
            initialUserMessage,
            { role: 'assistant', content: currentContext || 'Viewport is empty or not available' },
            { role: 'user', content: prompt }
          ];

        } else if (modelToUse === 'model-3') {
          // Gemini

          messages = localContext ? [
            { role: 'user', content: `You are editing the following element: ${localContextOuterHTML}, \n ${prompt}` }
          ] : [
            { role: 'model', content: currentContext },
            { role: 'user', content: prompt }
          ];
        } else if (modelToUse === 'model-4') {
          // GPT-4o Mini
          let initialUserMessage = { role: 'user', content: "You only output code, do not speak to me in any way except outputing code for my request." };

          messages = localContext ? [
            initialUserMessage,
            { role: 'user', content: `You are editing the following element: ${localContextOuterHTML}, \n ${prompt}` }
          ] : [
            initialUserMessage,
            { role: 'assistant', content: currentContext },
            { role: 'user', content: prompt }
          ];

        }

        if (!messages) throw new Error("There was an error constructing messages.");

        // Run completions,
        setIsGeneratingSpinner(true);
        // LAMBDA CODE START 

           const id = user?.id
           console.log('HELLO ID = ',id)
           payload = {
             FunctionName: lambdaFunctionName,
             InvocationType: 'RequestResponse',
             Payload: JSON.stringify({
               messages: messages,
               options: {
                 model: modelToUse,
                 mode: selectedProjectMode,
                 keepcontext: addNewPageContextHelperPrompt,
                 userId: id
               }
             }),
           };

           const lambdaResponse = await lambda.invoke(payload).promise();
           const lambdaPayload = JSON.parse(lambdaResponse.Payload as any);
           if (lambdaPayload.statusCode === 403) {
            console.log("FREE_TRIAL user has reached prompt limit");
            //  you can add logic here to show a modal or message to the user sarrr
            setIsGeneratingSpinner(false);
            setAreIconsDisabled(true);
            setIconDisabled(true);
            onUpgradeOpen();
            return; 
        }

           let responseCompletions = lambdaPayload.body;    
        // LAMBDA CODE END  

        // let responseCompletions: any = await getCompletions(messages, { model: modelToUse, mode: selectedProjectMode, keepcontext: addNewPageContextHelperPrompt });
        if (!responseCompletions) throw new Error("There was an error getting an output.");

        console.log(responseCompletions); // Completion log

        if (fromTemplate) {
          responseCompletions = JSON.stringify(convertToJson(splitAndAnalyzeContent(responseCompletions)), null, 2);
        }

        let parsedResponseCompletions = completionParser(responseCompletions);
        if (!parsedResponseCompletions) throw new Error("There was an error parsing the output.");

        if (!Array.isArray(parsedResponseCompletions)) {
          parsedResponseCompletions = [parsedResponseCompletions];
        }

        output = parsedResponseCompletions;

        console.log("output, parsed completion: ", output);

        // This whole block should only run if we're on packages 2 and up.
        if (minimalStylingDetection.includes(user?.plan_id as string)) {
          // Check if first completion
          if (firstCompletion) {
            let isCssEmpty: boolean = true;

            // Check if CSS is empty
            const isFoundCss = output.forEach((block: any) => {
              if (block.type === "css" && block.code) {
                isCssEmpty = false;
              }
            })

            // Trigger glow, to hint at enhance.
            // if (isCssEmpty && user?.plan_id != 'FREE_TRIAL') {
            if (isCssEmpty) {
              setIsGlowingEnhance(true);

              toast({
                title: "Minimal styling detected.",
                description: 'We noticed that your site isn\'t styled enough, click on the glowing button to enhance.',
                variant: 'solid',
                status: 'info',
                position: 'bottom',
                duration: 4000,
              });
            }


            // Trigger a feedback, to let the user know
            // why we want him to click on that?

            // Trigger autofixer, with type
            // setAutofixer("no-css");
          }

        }



        // Add effect for autofixer in dshboard to handle enhance, and pass type
        // as options


        // Postprocessing,

        if (!output) throw new Error("New usable output to work with.");
        await Promise.all(
          output.map(
            async (o: any) => {
              await postprocessUsableJSON(o);
            }
          )
        )

        return output;

      }

      catch {
        let retryChatBubble = "Trying again..";

        if (currentAttempt === 1) {
          retryChatBubble = 'Let me try again, do not worry..';
        } else if (currentAttempt === 2) {
          retryChatBubble = 'Let me try a second time..';
        } else if (currentAttempt === 3) {
          retryChatBubble = 'One last time..';
        }

        setChatBubbles((prev) => [...prev, {
          date: Date.now(),
          type: 'message',
          source: 'assistant',
          content: retryChatBubble
        }]);

        if (currentAttempt === 2) {
          toast({
            description: 'Trying a second time.',
            variant: 'solid',
            status: 'info',
            position: 'bottom-left',
            duration: 3000,
          });
        }

        if (currentAttempt === 3) {
          toast({
            description: 'Second time failed, trying a third time.',
            variant: 'solid',
            status: 'info',
            position: 'bottom-left',
            duration: 3000,
          });
        }

        currentAttempt++;
      } finally {
        clearTimeout(timer);
        setIsGeneratingLoadingScreen(false);
        setIsGeneratingSpinner(false);
      }

    }

    if (currentAttempt === 4) {
      setChatBubbles((prev) => [...prev, {
        date: Date.now(),
        type: 'message',
        source: 'assistant',
        content: 'Please reformulate your prompt and try again.'
      }]);

      // toast({
      //   description: 'Please try reformulating your prompt and try again.',
      //   variant: 'solid',
      //   status: 'error',
      //   position: 'bottom-left',
      //   duration: 6000,
      // });
    }

  }



  const generateJSON = async (transcription: string, options?: any) => {

    if (aiActionsFunctionCalling.includes(user?.plan_id as string) && userPreferences.ai_actions) {
      const function_call: any = await triggerFunctionCall(transcription);
      console.log(function_call);

      if (function_call && function_call.name === 'add_content') {
        return runChatCompletion(transcription, options);
      } else {
        setTriggeredFunction(function_call);
      }
    } else {
      return runChatCompletion(transcription, options);
    }

  }

  const extractAndPlaceAlongside = (htmlList: any) => {
    console.log(" ############### htmlList ############### ", htmlList);

    let htmlObj = null;

    for (const obj of htmlList) {
      if (obj.type === "html") {
        htmlObj = obj;
        break;
      }
    }

    if (!htmlObj) {
      return htmlList;
    }

    const cssJsObjects = [];
    const newChildren = [];

    if (htmlObj.element && htmlObj.element.children) {
      for (const child of htmlObj.element.children) {
        if (child.type === "css" || child.type === "javascript") {
          cssJsObjects.push(child);
        } else {
          newChildren.push(child);
        }
      }
    }

    const updatedList = [];
    for (const obj of htmlList) {
      if (obj === htmlObj) {
        updatedList.push(htmlObj);
        updatedList.push(...cssJsObjects);
      } else {
        updatedList.push(obj);
      }
    }

    console.log("htmlObj.element", htmlObj.element);
    console.log("htmlObj.element.children", htmlObj.element.children);

    htmlObj.element.children = newChildren;

    return updatedList;
  }

  const processJSON = (generatedElementsJSON: any) => {
    const usableObjects: any = {
      html: [],
      css: [],
      dependencies: [],
      javascript: []
    };

    if (!generatedElementsJSON) return;

    // let arrayOfObjects: any = [];

    const nestedArrayCorrected = generatedElementsJSON.map((object: any) => {
      if (Array.isArray(object)) {
        return object[0];
      } else {
        return object;
      }
    })

    const misplacedChildrenCorrected = nestedArrayCorrected.map((object: any) => {
      let temporaryStore: any;

      if (object.type === "html" && object.children && Array.isArray(object.children)) {
        temporaryStore = object.children;

        delete object.children;
        object.element.children = temporaryStore;

        return object;
      } else {
        return object;
      }
    })

    const misplacedCssandJsCorrected = extractAndPlaceAlongside(misplacedChildrenCorrected);

    // if (!Array.isArray(generatedElementsJSON[0])) {
    //   arrayOfObjects = generatedElementsJSON;
    // } else {
    //   arrayOfObjects = generatedElementsJSON[0];
    // }

    misplacedCssandJsCorrected.forEach((object: any) => {
      if (object.type === 'html') {
        if (object.element) {
          const generatedHTMLElement = generateHTMLElement(object.element);
          usableObjects.html.push(generatedHTMLElement);
        }
      } else if (object.type === 'css') {
        if (object.code) {
          const generatedCSSBlock = generateCSSCode(object.code);
          usableObjects.css.push(generatedCSSBlock);
        }
      } else if (object.type === 'javascript') {
        if (object.code) {
          const generatedJSCode = generateJSCode(object.code);
          usableObjects.javascript.push(generatedJSCode);
        }

        if (object.dependencies && object.dependencies.length > 0) {
          usableObjects.dependencies = [...object.dependencies];
        }
      }
    });

    return usableObjects;
  };

  const generateHTMLElement = (generatedHTMLElement: any) => {
    const elementTag = generatedHTMLElement.tag;

    // Skip undefined and script tags.
    if (elementTag && elementTag === undefined) return;

    // if (elementTag && elementTag.toLowerCase() === "script") {
    //   // Grab it's content...
    // }

    const element = document.createElement(elementTag);

    if (generatedHTMLElement.attributes) {
      Object.entries(generatedHTMLElement.attributes).forEach((e: any) => {
        element.setAttribute(e[0], e[1]);
      })
    }

    element.setAttribute('data-parent', generatedHTMLElement.parent || 'body');
    element.setAttribute('data-is-before', generatedHTMLElement.is_before);
    element.setAttribute('data-is-after', generatedHTMLElement.is_after);

    if (element.tagName === "FORM") {
      element.setAttribute("onsubmit", "return false");
    }

    if (generatedHTMLElement.text)
      element.innerHTML = generatedHTMLElement.text;

    if (generatedHTMLElement.children && generatedHTMLElement.children.length > 0) {
      generatedHTMLElement.children.forEach((child: any) => {
        const childElement = generateHTMLElement(child);
        element.appendChild(childElement);
      })
    }

    return element;
  }

  const generateCSSCode = (generatedCSSCode: any) => generatedCSSCode;
  const generateJSCode = (generatedJSCode: any) => generatedJSCode;

  useEffect(() => {
    if (!isInitialMount.current) {
      const generateObjects = async () => {

        if (transcription) {
          setChatBubbles((prev) => [...prev, {
            date: Date.now(),
            type: 'message',
            source: 'user',
            content: transcription
          }]);

          const JSON = await generateJSON(transcription);
          const processed = processJSON(JSON);

          if (processed && processed.html) {
            const scriptTagInHtml = processed.html.find((elem: any) => {
              if (elem.tagName.toLowerCase() === "script") {
                return true;
              }
            })

            if (scriptTagInHtml) {
              processed.javascript.push(scriptTagInHtml.innerHTML);
            }
          }

          setObjects(processed);
          setLocalContext(null);
          resetTranscriptionOutput();
        }

      };

      generateObjects();
    } else
      isInitialMount.current = false;
  }, [transcription]);

  useEffect(() => {
    if (selected_page)
      updatePageConversationHistory(selected_page.id, conversationHistory);
  }, [conversationHistory])

  useEffect(() => {
    if (selected_page)
      updateChat(selected_page.id, chatBubbles);
  }, [chatBubbles])

  const { postprocess, process } = useSIFOActions();

  useEffect(() => {
    if (!isInitialMount.current) {
      const generateObjects = async () => {
        if (enhanced) {

          console.log('enhanced: ', enhanced);
          const parsedEnhanceCompletion = completionParser(enhanced as any);;
          if (!parsedEnhanceCompletion) return;

          console.log('parsedEnhanceCompletion: ', parsedEnhanceCompletion);
          // const cleaned = cleanup(enhanced);

          // if (!cleaned) return;
          // const parsed = parse(cleaned);

          await Promise.all(
            parsedEnhanceCompletion.map(async (obj: any) => {
              await postprocess(obj);
            })
          )

          const processed = process(parsedEnhanceCompletion);

          setObjects(processed);
          setLocalContext(null);
        }

      };

      generateObjects();
    } else
      isInitialMount.current = false;

  }, [enhanced]);

  async function tryToFixErrorWithAi(errorMessage: string) {
    // Fix (Re-run completion with error as input.)

    setWillRepairJavascript(true);

    const JSON = await generateJSON(errorMessage, { temporarySystemMessage: { enable: true, type: "javascript-fixer" } });
    const processed = processJSON(JSON);

    setObjects(processed);
    setLocalContext(null);
    resetTranscriptionOutput();

    console.log('Attempting to fix the error.');
  }

  return (
    <>
      <Renderer
        handleClickInProcessorWhenMobile={handleClickInProcessorWhenMobile}
        objects={objects}
        tryToFixErrorWithAi={tryToFixErrorWithAi}
        isEnhanceCoolingDown={isEnhanceCoolingDown}
      />

      <AlertDialog
        isOpen={isUpgradeOpen}
        leastDestructiveRef={cancelRef}
        onClose={onUpgradeClose}
        isCentered
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent boxShadow="lg" p={5} borderRadius="md" bg="gray.800">
            <AlertDialogHeader
              fontSize="2xl"
              fontWeight="bold"
              display="flex"
              alignItems="center"
              color="white"
            >
              <Tooltip label="Upgrade to unlock all features" aria-label="Upgrade tooltip">
                <Icon as={InfoOutlineIcon} color="purple.500" mr={3} />
              </Tooltip>
              Unlock Full Access
            </AlertDialogHeader>

            <AlertDialogBody fontSize="lg" mt={4} display="flex" flexDirection="column" alignItems="center" color="gray.200">
              <MotionBox
                animate={{ rotate: [0, 360] }}
                transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }}
                mb={6}
              >
                <Icon as={InfoOutlineIcon} w={16} h={16} color="purple.400" />
              </MotionBox>
              <Text textAlign="center">
                <Text as="span" fontWeight="bold">
                  Your free prompts have reached their limit.
                </Text>{" "}
                Upgrade now to unlock unlimited prompts and complete your project with SIFO's full suite of features.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter justifyContent="center" mt={6}>
              <Button
                size="md"
                variant="outline"
                ref={cancelRef}
                onClick={onUpgradeClose}
                mr={3}
                color="white"
                _hover={{ bg: "gray.700" }}
              >
                Cancel
              </Button>
              <Button
                size="md"
                colorScheme="purple"
                onClick={() => navigate('/upgrade')}
                _hover={{ boxShadow: "0 0 10px rgba(128, 90, 213, 0.8)" }}
                variant='outline'>
                Unlock Now
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </>
  );
};