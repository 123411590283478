import React, { useState, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { AuthenticatedLayout } from '../layouts';
import { usePageActions } from '../hooks';
import { selectedPageSelector } from '../state';
// import { html as beautify_html } from 'js-beautify';

export const PreviewPage: React.FC = () => {

  const [previewDocument, setPreviewDocument] = useState('');

  const page = useRecoilValue(selectedPageSelector);
  const { fetchPageContent } = usePageActions();

  useEffect(() => {

    const preview = async () => {
      if (page) {
        const pageContent = await fetchPageContent(page.id) as string;
        if (!pageContent) return;

        const parser = new DOMParser();
        let pageDocument = parser.parseFromString(pageContent, 'text/html');

        const sectionLabels = pageDocument.querySelectorAll('.sifo-section-label');
        sectionLabels.forEach((sectionLabel: any) => sectionLabel.remove());

        const developmentElements = pageDocument.querySelectorAll('[data-dev-tag="sifo-dev"]');
        developmentElements.forEach(devElement => devElement.remove());

        const injectIntoScript = pageDocument.querySelector('[data-dev-tag="inject-into"]');
        injectIntoScript?.removeAttribute('data-dev-tag');
        if (!injectIntoScript?.textContent) injectIntoScript?.remove();

        const elementsWithPositioningAttributes = pageDocument.querySelectorAll('[data-parent]');
        elementsWithPositioningAttributes.forEach(elementWithPositioningAttributes => {
          elementWithPositioningAttributes.removeAttribute('data-parent');
          elementWithPositioningAttributes.removeAttribute('data-is-before');
          elementWithPositioningAttributes.removeAttribute('data-is-after');
        });

        const pageDocumentStringified = pageDocument.documentElement.outerHTML
        setPreviewDocument(pageDocumentStringified);
      }
    };

    preview();
  }, []);

  return (
    <AuthenticatedLayout>
      <Flex flex={1} flexDirection={'column'} gap={3} w='full'>
        <Flex flex={1} flexDirection={{ base: "column", md: "row" }} gap={3} overflowY={'auto'}>
          <iframe
            id='sifo-preview-iframe'
            style={{ height: '100%', width: '100%', borderRadius: '3px', backgroundColor: 'white' }}
            title="Preview"
            allowFullScreen
            srcDoc={previewDocument}
          />

          <iframe
            name="submission_target_frame"
            style={{ display: 'none' }}
          />
        </Flex>
      </Flex>
    </AuthenticatedLayout>
  );
};