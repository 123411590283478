import { useCallback } from 'react';
import { ApiClient } from '../lib/clients/api-client';
import { useApiActions } from './api-actions';
import { writeCSSFromRules } from '../components/engine/css-tools';
import { cssjs } from 'jotform-css.js';
import { insertStyles } from '../components/engine/css-tools';
import { insertJavascript } from '../components/engine/js-tools';
import { useSetRecoilState } from 'recoil';
import { OutlinerTree } from '../state';

// const transcribe = async (audio_file: any) =>
//     await executeApiAction({
//         action: () => ApiClient.transcribe(audio_file),
//         errorMessage: 'Audio transcription failed.',
//         successMessage: 'Audio transcription successfull.',
//     });

// const complete = async (prompt: string) =>
//     await executeApiAction({
//         action: () => ApiClient.getCompletions(prompt),
//         errorMessage: 'Chat completion failed.',
//         successMessage: 'Chat completion successful.',
//     });

// The others can wait, this should be enough for achieving what I want to achieve.

export const useSIFOActions = () => {
    const { executeApiAction } = useApiActions();
    const setOutlinerTree = useSetRecoilState(OutlinerTree);

    const buildLocalizationMap = (viewport: any) => {
        const textElements: any = {};
        const elementsWithText = viewport.querySelectorAll('div#body *');

        elementsWithText.forEach((element: any) => {

            // console.log("### Element with Text: ", element);

            // Loop over all and any element
            
            const childNodes = Array.from(element.childNodes);
            
            //  Grab childones of said element

            // console.log("### Child nodes of Element with Text: ", element.childNodes);

            // Filter said child nodes for plain text ones.

            const hasPlainTextChild = childNodes.some((node: any) => {
                if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
                    console.log("This is a plain text node: ", node);
                    
                    return node;
                }

                // return node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '';
            });

            let id: string = element.id;
            let text: string | null = null;

            if (hasPlainTextChild) text = element.textContent.trim();

            if (id && text) {
                textElements[id] = text;
            }
        });

        return textElements;
    }

    const unwantedTags = ["html", "body", "head", "meta", "link", "style"];

    const buildOutlinerTree = (viewport: any) => {
        const loop = (node: any) => {

            if (unwantedTags.includes(node.tagName.toLowerCase())) return;

            const element: any = {
                id: node.id || null,
                tag: node.tagName.toLowerCase() || null,
                children: []
            };

            const children = node.children;
            for (let i = 0; i < children.length; i++) {
                element.children.push(loop(children[i]));
            }

            return element;
        }

        const rootElement = viewport.querySelector('div#body');

        let rootChildren: any = [];
        if (rootElement) rootChildren = Array.from(rootElement.children);

        const outlinerTree: any = [
            ...rootChildren.map(loop),
        ];

        return outlinerTree;
    }

    const buildContextTree = (viewport: any) => {
        const loop = (node: any) => {
            // const element: any = {
            //     tag: node.tagName.toLowerCase(),
            //     attributes: {},
            //     text: '',
            //     // text: node.textContent.trim(),
            //     parent: node.parentElement ? (node.parentElement.id || '') : '',
            //     is_before: '',
            //     is_after: '',
            //     children: []
            // };

            const element: any = {
                tag: node.tagName.toLowerCase(),
                attributes: {},
                text: '',
                parent: node.parentElement ? (node.parentElement.id || '') : '',
                children: []
            };

            const cn = Array.from(node.childNodes);

            const hasPlainTextChild = cn.some((node: any) => {
                return node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '';
            });

            if (hasPlainTextChild) element.text = node.textContent.trim();

            // if (node && node.nodeType === Node.ELEMENT_NODE) {
            //     const hasElementChildren = Array.from(node.childNodes).some((node: any) => node.nodeType === Node.ELEMENT_NODE);
            //     if (hasElementChildren) element.text = '';
            //     else element.text = node.textContent.trim();
            // }

            for (let i = 0; i < node.attributes.length; i++) {
                const attr = node.attributes[i];
                const attributeName = attr.name.toLowerCase();

                if (
                    attributeName !== 'data-parent' &&
                    attributeName !== 'data-is-before' &&
                    attributeName !== 'data-is-after'
                ) {
                    element.attributes[attr.name] = attr.value;
                }
            }

            const children = node.children;
            for (let i = 0; i < children.length; i++) {
                element.children.push(loop(children[i]));
            }

            const outlinerTree = buildOutlinerTree(viewport);
            setOutlinerTree(outlinerTree);

            return node.parentElement.id === 'body' ? {
                type: 'html',
                element
            } : element;

        }

        const rootElement = viewport.querySelector('div#body');

        let rootChildren: any = [];
        if (rootElement) rootChildren = Array.from(rootElement.children);

        // const tree: any = {
        //     html: [...rootChildren.map(loop)],
        //     css: viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML,
        //     javascript: viewport.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML
        // };

        // const tree: any = {
        //     output: [
        //         ...rootChildren.map(loop),
        //         {
        //             type: "css",
        //             code: viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML
        //         },
        //         {
        //             type: "javascript",
        //             code: viewport.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML
        //         }
        //     ]
        // };

        const tree: any = [
            ...rootChildren.map(loop),
            {
                type: "css",
                code: viewport.querySelector('style[data-dev-tag="inject-into"]')?.innerHTML
            },
            {
                type: "javascript",
                code: viewport.querySelector('script[data-dev-tag="inject-into"]')?.innerHTML
            }
        ];

        return JSON.stringify(tree);
    }

    const cleanup = (completion: string) => {

        // const extractJSONArray = (input: string) => {
        //     const startIndex = input.indexOf('[');
        //     const endIndex = input.lastIndexOf(']');

        //     if (startIndex !== -1 && endIndex !== -1) return input.substring(startIndex, endIndex + 1);
        //     else return null;
        // }

        const extractJSONArray = (str: string) => {
            let openIndex = -1;
            let closeIndex = -1;

            for (let i = 0; i < str.length; i++) {
                if (str[i] === '[' || str[i] === '{') {
                    openIndex = i;
                    break;
                }
            }

            if (openIndex === -1) {
                return "";
            }

            for (let i = str.length - 1; i >= 0; i--) {
                if (str[i] === ']' || str[i] === '}') {
                    closeIndex = i;
                    break;
                }
            }

            if (closeIndex === -1 || closeIndex < openIndex) {
                return "";
            }

            return str.substring(openIndex, closeIndex + 1);
        }

        return extractJSONArray(completion);

    }

    const parse = (completion: string) => {
        let parsedCompletion = JSON.parse(completion);
        // let output = parsedCompletion.output;

        if (!Array.isArray(parsedCompletion)) {
            parsedCompletion = [parsedCompletion];
        }

        return parsedCompletion;
    }

    const postprocess = async (obj: any) => {

        const extractImageGenerationKeywordFromURL = (url: string) => {
            const regex = /\?([A-Za-z0-9\-_&]*)/;
            const matches = url.match(regex);

            if (matches && matches.length > 1) {
                return matches[1];
            } else return null;
        }

        const extractSelector = (selector: string) => {
            const cleanedInput = selector.replace(/[^a-zA-Z0-9-]/g, '');
            const finalInput = cleanedInput.replace(/^-+|-+$/g, '');
            return finalInput;
        }

        

        // Can I make this return the final value afterwards, I think I can?
        // It might end up fixing that JS bug too, who knows?
        // I need to loop inside here, not from outside.

    }

    const process = (generatedElementsJSON: any) => {

        const generateHTMLElement = (generatedHTMLElement: any) => {
            const element = document.createElement(generatedHTMLElement.tag);

            if (generatedHTMLElement.attributes) {
                Object.entries(generatedHTMLElement.attributes).forEach((e: any) => {
                    element.setAttribute(e[0], e[1]);
                })
            }

            element.setAttribute('data-parent', generatedHTMLElement.parent || 'body');
            element.setAttribute('data-is-before', generatedHTMLElement.is_before);
            element.setAttribute('data-is-after', generatedHTMLElement.is_after);

            if (generatedHTMLElement.text)
                element.innerHTML = generatedHTMLElement.text;

            if (generatedHTMLElement.children && generatedHTMLElement.children.length > 0) {
                generatedHTMLElement.children.forEach((child: any) => {
                    const childElement = generateHTMLElement(child);
                    element.appendChild(childElement);
                })
            }

            return element;
        }

        const generateCSSCode = (generatedCSSCode: any) => generatedCSSCode;
        const generateJSCode = (generatedJSCode: any) => generatedJSCode;

        const usableObjects: any = {
            html: [],
            css: [],
            javascript: []
        };

        if (!generatedElementsJSON) return;

        generatedElementsJSON.forEach((object: any) => {

            if (object.type === 'html') {
                if (object.element) {
                    const generatedHTMLElement = generateHTMLElement(object.element);
                    usableObjects.html.push(generatedHTMLElement);
                }
            } else if (object.type === 'css') {
                if (object.code) {
                    // If normal mode
                    const generatedCSSBlock = generateCSSCode(object.code);
                    usableObjects.css.push(generatedCSSBlock);


                    // If enhance, and in localContext
                    // const css_parser = new cssjs();

                    // const selectors = getIDsRecursively(localContext);
                    // const parsed = css_parser.parseCSS(cssCode);
                    // const filtered = deleteCSSRulesBySelectors(parsed, selectors);
                    // const filteredGlobalCSS: any = writeCSSFromRules(filtered);

                    // // The error is here, what did I do before this, and this should be parsed, and re-written. Parse this, and grab the code from it.
                    // const newCSS = filteredGlobalCSS.concat('\n\n', enhanced);
                    // setCSSCode(newCSS);
                    // const parsedNewCSS = css_parser.parseCSS(newCSS);

                    // const elementCSS = extractCSSRulesBySelectors(parsedNewCSS, selectors)
                    // if (!elementCSS) return;

                    // const elementCSSString = writeCSSFromRules(elementCSS);
                    // if (!elementCSSString) return;
                    // setLocalContextCSS(elementCSSString);

                    // setIsUpdated(true);


                }
            } else if (object.type === 'javascript') {
                if (object.code) {
                    const generatedJSCode = generateJSCode(object.code);
                    usableObjects.javascript.push(generatedJSCode);
                }
            }

        });

        return usableObjects;
    }

    const inject = (iframeReference: any, objects: any) => {

        const insertElement = (element: HTMLElement) => {
            const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
            if (!viewport) return;

            const parentElementId = element.getAttribute("data-parent");
            if (!parentElementId) return;

            const parentElement = viewport.getElementById(parentElementId);
            if (!parentElement) return;

            const existingElement = viewport.querySelector(`#${element.id}`);
            const previousElementID = element.getAttribute("data-is-after");
            const nextElementID = element.getAttribute("data-is-before");

            if (nextElementID) {
                const nextElement = viewport.querySelector(`#${nextElementID}`);
                parentElement.insertBefore(element, nextElement);

                if (existingElement) existingElement.remove();
            } else if (previousElementID) {
                const previousElement = viewport.querySelector(`#${previousElementID}`);
                if (previousElement) previousElement.insertAdjacentElement('afterend', element);

                if (existingElement) existingElement.remove();
            } else {
                if (existingElement) existingElement.replaceWith(element);
                else parentElement.append(element);
            }
        }

        const viewport = iframeReference.current?.contentDocument || iframeReference.current?.contentWindow?.document;
        if (viewport && objects) {
            if (objects.html) {
                objects.html.forEach((HTMLElement: HTMLElement): void => {
                    insertElement(HTMLElement);
                    if (HTMLElement.tagName.toLowerCase() === 'div') iframeReference.current?.contentWindow?.postMessage(HTMLElement.id, '*');
                });
            }

            if (objects.css) {
                objects.css.forEach((CSSBlock: string) => {
                    const styleElement = viewport.querySelector('style[data-dev-tag="inject-into"]');
                    if (styleElement) styleElement.innerHTML = insertStyles(styleElement.innerHTML, CSSBlock);
                    // if (styleElement) styleElement.innerHTML += CSSBlock + '\n';
                })
            }

            if (objects.javascript) {
                objects.javascript.forEach((JSCode: string) => {
                    const scriptElement = viewport.querySelector('script[data-dev-tag="inject-into"]');
                    if (scriptElement) scriptElement.innerHTML = insertJavascript(scriptElement.innerHTML, JSCode);
                    // if (scriptElement) scriptElement.innerHTML += JSCode + '\n';
                })
            }

        }
    }

    return {
        // transcribe: useCallback(transcribe, [executeApiAction]),
        // complete: useCallback(complete, [executeApiAction]),
        buildLocalizationMap: useCallback(buildLocalizationMap, [executeApiAction]),
        buildContextTree: useCallback(buildContextTree, [executeApiAction]),
        buildOutlinerTree: useCallback(buildOutlinerTree, [executeApiAction]),
        cleanup: useCallback(cleanup, [executeApiAction]),
        parse: useCallback(parse, [executeApiAction]),
        postprocess: useCallback(postprocess, [executeApiAction]),
        process: useCallback(process, [executeApiAction]),
        inject: useCallback(inject, [executeApiAction]),
    };
};