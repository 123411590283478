import React, { useState, useMemo, useEffect } from 'react';
import { Flex, Image, Progress, Heading, Text, useDisclosure } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react'
import { IsGeneratingLoadingScreen, IsGeneratingSpinner } from '../../state';
import { useRecoilValue } from 'recoil';

import { ReactComponent as Pulse1 } from './loading-animations/Pulse1.svg';
import { ReactComponent as Pulse2 } from './loading-animations/Pulse2.svg';
import { ReactComponent as Pulse3 } from './loading-animations/Pulse3.svg';
import { ReactComponent as Pulse4 } from './loading-animations/Pulse4.svg';
import { ReactComponent as Pulse5 } from './loading-animations/Pulse5.svg';
import { ReactComponent as Pulse6 } from './loading-animations/Pulse6.svg';
import { ReactComponent as Pulse7 } from './loading-animations/Pulse7.svg';
import { ReactComponent as Pulse8 } from './loading-animations/Pulse8.svg';

const Loader = () => {
  const animations = [Pulse1, Pulse2, Pulse3, Pulse4, Pulse5, Pulse6, Pulse7, Pulse8];

  const getRandomAnimation = () => {
    const randomIndex = Math.floor(Math.random() * animations.length);
    return animations[randomIndex];
  };

  const RandomAnimation = getRandomAnimation();

  return <RandomAnimation />;

  // return (
  //   <div>
  //     <img src={randomAnimation} alt="Random SVG Animation" />
  //   </div>
  // );
};

export const CompletionLoadingScreen: React.FC<any> = () => {

  const [displayedLoadingMessage, setDisplayedLoadingMessage] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { isOpen: isCompletionLoadScreenOpen, onOpen: onCompletionLoadScreenOpen, onClose: onCompletionLoadScreenClose } = useDisclosure();
  const isGeneratingLoadingScreen = useRecoilValue(IsGeneratingLoadingScreen);
  const isGeneratingSpinner = useRecoilValue(IsGeneratingSpinner);

  const loadingMessages = useMemo(() => [
    "Patience is a virtue... and a necessity!",
    "Grab a snack, we'll be done soon!",
    "Rome wasn't built in a day, neither is this.",
    "Time to practice your deep breathing.",
    "Counting pixels... Just kidding, almost there!",
    "Making the digital universe align just for you.",
    "Hang tight, we're almost ready to dazzle you!",
    "Good things come to those who wait, right?",
    "Somewhere, a progress bar is smiling.",
    "Stay calm and watch the loading wheel spin!",
    "The suspense is killing us too!",
    "Waiting... The slowest form of time travel.",
    "In the meantime, why not ponder the meaning of life?",
    "Almost there... And by almost, we mean not quite yet.",
    "Hold on tight, we're taking a detour through cyberspace.",
    "The digital hamsters are working overtime!",
    "Just a moment... We're conjuring digital magic for you.",
    "Cue the dramatic music!",
    "Don't blink! Well, maybe just until we finish loading.",
    "We promise it's worth the wait. Probably.",
    "Time flies when you're... oh wait, no it doesn't.",
    "Keep calm and let the loading screen do its thing."
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = Math.floor(Math.random() * loadingMessages.length);
      setCurrentIndex(newIndex);
    }, 5000);

    return () => clearInterval(interval);
  }, [loadingMessages]);

  useEffect(() => {
    setDisplayedLoadingMessage(loadingMessages[currentIndex]);
  }, [currentIndex, loadingMessages]);

  useEffect(() => {
    if (isGeneratingLoadingScreen === true) {
      if (isGeneratingSpinner === true) {
        onCompletionLoadScreenOpen()
      }
    }

    if (isGeneratingLoadingScreen === false) {
      onCompletionLoadScreenClose()
    }
  }, [isGeneratingLoadingScreen])

  return (
    <Modal size='xs' isOpen={isCompletionLoadScreenOpen} onClose={onCompletionLoadScreenClose} isCentered>
      <ModalOverlay />
      <ModalContent py={4}>
        <ModalBody>
          <Flex flexDir='column' gap={4} alignItems='center' p={4}>
            <Loader />
            <Heading fontSize='md'>Please wait...</Heading>
            <Flex flexDir='column' gap={3} w='full' alignItems='center'>
              <Text >{displayedLoadingMessage}</Text>
              <Progress w='full' size='sm' colorScheme="purple" isIndeterminate />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};